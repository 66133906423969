import i18n from '@/i18n'
import Store from '@/store'
// import { Time } from 'highcharts'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, data, thisObject) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }

    // Construction Top Table Details
    const pdfContent = [
      { text: reportTitle, style: 'header2', alignment: 'center' }
    ]

    const reportHeader = [
      [
        { text: '1', style: 'th', alignment: 'center', rowSpan: 5 },
        { text: i18n.locale === 'bn' ? thisObject.$t('irriPumpInformation.project') + ':      ' + data.project_name_bn + '                                                             ' + data.org_name_bn : thisObject.$t('irriPumpInformation.project') + ':  ' + data.project_name + '                                                                  ' + data.org_name, style: 'th', alignment: 'left', rowSpan: 5, colSpan: 3 },
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.well_no'), style: 'th', alignment: 'left' },
        { text: data.well_no, style: 'th', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('globalTrans.district'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? data.district_name_bn : data.district_name, style: 'th', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.upazila'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? data.upazilla_name_bn : data.upazilla_name, style: 'th', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('globalTrans.union'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? data.union_name_bn : data.union_name, style: 'th', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.mouza'), style: 'thStatic', alignment: 'left' },
        { text: i18n.locale === 'bn' ? thisObject.$n(data.mouza_no) : data.mouza_no, style: 'thStatic', alignment: 'left' }
      ],
      [
        { text: i18n.locale === 'bn' ? thisObject.$t('irriPumpInformation.engineer') + ' : ' + data.engineer_name_bn : thisObject.$t('irriPumpInformation.engineer') + ' :                                      ' + data.engineer_name, style: 'th', alignment: 'center', rowSpan: 3, colSpan: 2 },
        {},
        { text: i18n.locale === 'bn' ? thisObject.$t('irriPumpInformation.drilling_contractor') + '                  ' + data.drilling_contractor_name_bn : thisObject.$t('irriPumpInformation.drilling_contractor') + '                         ' + data.drilling_contractor_name, style: 'th', alignment: 'center', rowSpan: 3, colSpan: 2 },
        {},
        { text: thisObject.$t('irriPumpInformation.jl_no'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? thisObject.$n(data.jl_no) : data.jl_no, style: 'th', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.plot_no'), style: 'th', alignment: 'left' },
        { text: i18n.locale === 'bn' ? thisObject.$n(data.plot_no) : data.plot_no, style: 'th', alignment: 'left' }
      ],
      [
        {},
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.drilling_logc'), style: 'th', alignment: 'center', colSpan: 2 },
        {}
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*'],
        body: reportHeader
      }
    })

    // Construction Middle Table Details
    const reportMiddle = [
      [
        { text: thisObject.$t('irriPumpInformation.operation'), style: 'th', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.started'), style: 'th', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.completed'), style: 'th', alignment: 'center' },
        { text: '', rowSpan: 5 },
        { text: thisObject.$t('irriPumpInformation.cross_section_of_the_well') + ' ' + thisObject.$t('irriPumpInformation.not_to_scale'), style: 'th', alignment: 'center', decoration: 'underline', rowSpan: 29 }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.drilling_diameter'), style: 'th', alignment: 'left' },
        { text: data.drilling_start_time + ' ' + thisObject.$t('irriPumpInformation.hrs'), style: 'th', alignment: 'right' },
        { text: data.drilling_complete_time + ' ' + thisObject.$t('irriPumpInformation.hrs'), style: 'th', alignment: 'right' },
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.casing_started'), style: 'th', alignment: 'left' },
        { text: data.casing_start_time + ' ' + thisObject.$t('irriPumpInformation.hrs'), style: 'th', alignment: 'right' },
        { text: data.casing_complete_time + ' ' + thisObject.$t('irriPumpInformation.hrs'), style: 'th', alignment: 'right' },
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.gravel_placing_started'), style: 'th', alignment: 'left' },
        { text: data.gravel_placing_complete_time + ' ' + thisObject.$t('irriPumpInformation.hrs'), style: 'th', alignment: 'right' },
        { text: data.gravel_placing_start_time + ' ' + thisObject.$t('irriPumpInformation.hrs'), style: 'th', alignment: 'right' },
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.drilling_diameter'), style: 'th', alignment: 'left' },
        { text: data.drilling_diameter + '"', style: 'th', alignment: 'center', colSpan: 2 },
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.housing_pipe_dia'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.uwc') + '........................' + thisObject.$n(data.uwc_above_gl) + '........................' + thisObject.$t('irriPumpInformation.UWC_above_GL'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.uwc') + '........................' + thisObject.$n(data.uwc_below_gl) + '........................' + thisObject.$t('irriPumpInformation.UWC_below_GL'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.total_length'), style: 'th', alignment: 'right', colSpan: 3 },
        {},
        {},
        { text: thisObject.$n(data.uwc_total_gl), style: 'th', alignment: 'left' },
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.LWC'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.depth_below_GL'), style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.from') + '..........' + thisObject.$n(data.lwc_from_gl) + '..........', style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.to') + '..........' + thisObject.$n(data.lwc_to_gl) + '..........' + thisObject.$t('irriPumpInformation.ft'), style: 'th', alignment: 'left' },
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.total_length'), style: 'th', alignment: 'right', colSpan: 3 },
        {},
        {},
        { text: thisObject.$n(data.lwc_total_gl), style: 'th', alignment: 'left' },
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.screen_dia'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.depth_below_GL'), style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.from') + '..........' + thisObject.$n(data.screen_from_gl) + '..........', style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.to') + '..........' + thisObject.$n(data.screen_to_gl) + '..........' + thisObject.$t('irriPumpInformation.ft'), style: 'th', alignment: 'left' },
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.total_length'), style: 'th', alignment: 'right', colSpan: 3 },
        {},
        {},
        { text: thisObject.$n(data.screen_total_gl), style: 'th', alignment: 'left' },
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.bail_plug'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.depth_below_GL'), style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.from') + '..........' + thisObject.$n(data.bail_plug_from_gl) + '..........', style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.to') + '..........' + thisObject.$n(data.bail_plug_to_gl) + '..........' + thisObject.$t('irriPumpInformation.ft'), style: 'th', alignment: 'left' },
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.centralizes'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.depth_below_GL') + ':' + data.centralize_description, style: 'th', alignment: 'left', colSpan: 4, rowSpan: 2 },
        {},
        {},
        {},
        {}
      ],
      [
        {},
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.tot_number'), style: 'th', alignment: 'left', colSpan: 3 },
        {},
        {},
        { text: thisObject.$n(data.centralize_total), style: 'th', alignment: 'left' },
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.gravel'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.gravel_quantity'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.depth_below_GL'), style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.from') + '..........' + thisObject.$n(data.gravel_depth_form) + '..........', style: 'th', alignment: 'left' },
        { text: thisObject.$t('irriPumpInformation.to') + '..........' + thisObject.$n(data.gravel_depth_to) + '..........' + thisObject.$t('irriPumpInformation.ft'), style: 'th', alignment: 'left' },
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.fixture_length'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: '....................' + thisObject.$n(data.fixture_length) + '....................' + thisObject.$t('irriPumpInformation.uwctoBail'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.verticality_test'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.max_deviation') + '....................' + thisObject.$n(data.max_deviation) + '....................' + thisObject.$t('irriPumpInformation.inch_at') + '....................' + thisObject.$n(data.max_depth) + '....................', style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.banglish'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('irriPumpInformation.date'), style: 'th', alignment: 'left', colSpan: 4 },
        {},
        {},
        {},
        { text: thisObject.$t('irriPumpInformation.ordered_depth') + thisObject.$t('irriPumpInformation.glToBailPlug'), style: 'th', alignment: 'center' }
      ]
    ]

    pdfContent.push({
      table: {
        heights: 5,
        headerRows: 1,
        widths: ['25%', '20%', '20%', '5%', '30%'],
        body: reportMiddle
      },
      style: 'tableMiddle'
    })

    const constructionFooter = [
      [
        { text: thisObject.$t('irriPumpInformation.sub_assistant_engineer'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.assistant_engineer'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.for_the_contractor'), style: '', alignment: 'center' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.bmda_dri'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.bmda'), style: '', alignment: 'center' },
        { text: thisObject.$t('irriPumpInformation.date'), style: '', alignment: 'center' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: constructionFooter
      },
      style: 'tableFooter',
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          tableMiddle: {
            margin: [0, 10, 0, 15]
          },
          tableFooter: {
            margin: [0, 60, 0, 0],
            fontSize: (i18n === 'bn') ? 11 : 9,
            bold: true
          },
          th: {
            fontSize: (i18n === 'bn') ? 11 : 9,
            margin: [3, 3, 3, 3],
            bold: true
          },
          thStatic: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [43, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: i18n.locale === 'bn' ? 14 : 12,
            margin: [0, 10, 0, 10]
          },
          header3: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 10, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
