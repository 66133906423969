<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.construction_details_report') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col class="text-right">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row v-show="dataShow">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.construction_details_report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
            </b-button>
            <export-excel
              class="btn btn_add_new"
              :data="dataCustomizeExcel"
              :fields="json_fields"
              :title="$t('irriPumpInformation.construction_details_report')"
              :worksheet="$t('irriPumpInformation.construction_details_report')"
              name="construction-details-report.xls">
              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">{{ data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      {{ data.item.division_name_bn }}
                    </template>
                    <template v-slot:cell(district_name_bn)="data">
                      {{ data.item.district_name_bn }}
                    </template>
                    <template v-slot:cell(district_name)="data">
                      <span class="capitalize">{{ data.item.district_name }}</span>
                    </template>
                    <template v-slot:cell(org_name_bn)="data">
                      {{ data.item.org_name_bn }}
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(pump_id)="data">
                      {{ $n(data.item.pump_id) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="View Complain" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-detail" size="xl" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.print') }}
      </b-button>
      <DetailModal :id="editItemId" :key="editItemId" :item="item" ref="details"/>
    </b-modal>
  </b-container>
</template>
<script>
import DetailModal from './DetailModal'
import { constructionDetailsReport } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import HierarchycalNames from '@/Utils/common'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'pump ID': 'pump_id',
  Project: 'project_name',
  Location: 'location',
  'Mouza No': 'mouza_no',
  'JL No': 'jl_no',
  'Plot No': 'plot_no'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'পাম্প আইডি': 'pump_id',
  প্রোজেক্ট: 'project_name_bn',
  অবস্থান: 'location',
  'মৌজা নং': 'mouza_no',
  'জেএল নং': 'jl_no',
  'প্লট নং': 'plot_no'
}

export default {
  mixins: [ModalBaseMasterList],
  components: {
    DetailModal
  },
  data () {
    return {
      dataShow: false,
      search: {
        org_id: '0',
        pump_id: '0',
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0'
      },
      rows: [],
      item: '',
      pumpDropdownList: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
        var serial = 0
        const listContractor = this.listData.map(item => {
          const allNames = HierarchycalNames.getCommonHierarchyNames(item.union_id, 'union')
          serial += 1
          return Object.assign({}, item, {
            serial: this.$n(serial),
            pump_id: this.$n(item.pump_id, { useGrouping: false }),
            location: `${allNames.divisionName}, ${allNames.districtName}, ${allNames.upazilaName}, ${allNames.unionName}`,
            jl_no: this.$n(item.jl_no, { useGrouping: false }),
            plot_no: this.$n(item.plot_no, { useGrouping: false })
          })
        })
        return listContractor
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('irriPumpInformation.pump_report_entry') : this.$t('irriPumpInformation.pump_report_entry') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
       return this.$t('irriPumpInformation.construction_details_report')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('irriPumpInformation.project'), class: 'text-left' },
          { label: this.$t('irriPumpInformation.pump_id'), class: 'text-left' },
          { label: this.$t('org_pro_division.division'), class: 'text-left' },
          { label: this.$t('org_pro_district.district'), class: 'text-left' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
          { label: this.$t('org_pro_union.union'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'project_name_bn' },
          { key: 'pump_id' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'union_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'project_name' },
          { key: 'pump_id' },
           { key: 'division_name' },
           { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'union_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
          } else {
              return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
          }
      })
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        division_id: this.authUser.office_detail.division_id,
        district_id: this.authUser.office_detail.district_id,
        upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          division_id: this.authUser.office_detail.division_id,
          district_id: this.authUser.office_detail.district_id,
          upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      if (this.search.division_id > 0) {
        this.loadData()
      }
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, constructionDetailsReport, this.search).then(response => {
        if (response.success) {
          this.dataShow = true
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.item = this.listData
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.orgList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const projectList = this.$store.state.IrriConfig.commonObj.projectList
      return data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const unionObject = unionList.find(union => union.value === item.union_id)
        const projectObject = projectList.find(project => project.value === item.project_id)
        const orgData = {
          logo: orgObject ? orgObject.logo : '',
          org_name: orgObject ? orgObject.text_en : '',
          org_name_bn: orgObject ? orgObject.text_bn : ''
        }
        const divisionData = {
          division_name: divisionObject.text_en,
          division_name_bn: divisionObject.text_bn
        }
        const districtData = {
          district_name: districtObject.text_en,
          district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
          upazilla_name: upazilaObject.text_en,
          upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
          union_name: unionObject.text_en,
          union_name_bn: unionObject.text_bn
        }
        const projectData = {
          project_name: projectObject.text_en,
          project_name_bn: projectObject.text_bn
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, projectData)
      })
    },
    getPumplist (orgId) {
        this.pumpInformationList = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pumpInfo => pumpInfo.org_id === orgId && pumpInfo.status === 0)
        return this.pumpInformationList.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.pump_id }
          } else {
            return { value: obj.id, text: obj.pump_id }
          }
        })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getProjectlist (orgId) {
      this.projectList = this.$store.state.IrriConfig.commonObj.projectList.filter(project => project.org_id === orgId && project.status === 0)
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.project'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.pump_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('pump_install.location'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.mouza'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.jl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.plot_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$t('irriPumpInformation.construction_details_report')
      const columnWids = ['7%', '18%', '10%', '30%', '*', '*', '*']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', 15, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'project_name_bn' },
          { key: 'pump_id' },
          { key: 'location' },
          { key: 'mouza_no' },
          { key: 'jl_no' },
          { key: 'plot_no' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'project_name' },
          { key: 'pump_id' },
          { key: 'location' },
          { key: 'mouza_no' },
          { key: 'jl_no' },
          { key: 'plot_no' }
        ]
      }
      var serial = 0
      return this.listData.map(item => {
        const allNames = HierarchycalNames.getCommonHierarchyNames(item.union_id, 'union')
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'location') {
                return { text: `${allNames.divisionName}, ${allNames.districtName}, ${allNames.upazilaName}, ${allNames.unionName}` }
            }
            if (keyItem.key === 'pump_id') {
              return { text: this.$n(item.pump_id, { useGrouping: false }) }
            }
            if (keyItem.key === 'jl_no') {
              return { text: this.$n(item.jl_no, { useGrouping: false }) }
            }
            if (keyItem.key === 'plot_no') {
              return { text: this.$n(item.plot_no, { useGrouping: false }) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })
    },
    pdfExportDetails () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
