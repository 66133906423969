<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col>
                        <h5 class="text-right">{{ $i18n.locale === 'bn' ? 'ফরম - বি' : 'Form - B' }}</h5>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-table-simple small bordered hover responsive class="text-center">
                        <tr>
                          <td rowspan="5" colspan="3" class="text-left" style="width:10%">
                            <img :src="commonServiceBaseUrl + 'download-attachment?file=uploads/org-profile/' + deepTubwell.logo" alt="Logo">
                          </td>
                          <td rowspan="5" colspan="3">
                            <p class="font-weight-bold text-left">{{ $t('irriPumpInformation.project') }}: {{ $i18n.locale === 'bn' ? deepTubwell.project_name_bn : deepTubwell.project_name }}</p>
                            <h5 class="font-weight-bold text-capitalize text-center">{{ $i18n.locale === 'bn' ? deepTubwell.org_name_bn : deepTubwell.org_name }}</h5>
                            <h5 class="font-weight-bold text-capitalize text-center">{{ $i18n.locale === 'bn' ? deepTubwell.division_name_bn : deepTubwell.division_name }}</h5>
                          </td>
                          <td class="text-left"><b>{{ $t('irriPumpInformation.well_no') }}</b></td>
                          <td>:</td>
                          <td class="text-left">{{ deepTubwell.well_no }}</td>
                        </tr>
                        <tr>
                          <td class="text-left"><b>{{ $t('org_pro_district.district') }}</b></td>
                          <td>:</td>
                          <td class="text-left">{{ $i18n.locale === 'bn' ? deepTubwell.district_name_bn : deepTubwell.district_name }}</td>
                        </tr>
                        <tr>
                          <td class="text-left"><b>{{ $t('org_pro_upazilla.upazilla') }}</b></td>
                          <td>:</td>
                          <td class="text-left">{{ $i18n.locale === 'bn' ? deepTubwell.upazilla_name_bn : deepTubwell.upazilla_name }}</td>
                        </tr>
                        <tr>
                          <td class="text-left"><b>{{ $t('org_pro_union.union') }}</b></td>
                          <td>:</td>
                          <td class="text-left">{{ $i18n.locale === 'bn' ? deepTubwell.union_name_bn : deepTubwell.union_name }}</td>
                        </tr>
                        <tr>
                          <td class="text-left"><b>{{ $t('irriPumpInformation.mouza_no') }}</b></td>
                          <td>:</td>
                          <td class="text-left">{{ deepTubwell.mouza_no }}</td>
                        </tr>
                        <tr>
                          <td colspan="5" rowspan="3" style="width:40%">
                            <p class="font-weight-bold text-center">{{ $t('irriPumpInformation.engineer') }} </p>
                            <p class="font-weight-bold text-center  mb-0 ">{{ $i18n.locale === 'bn' ? deepTubwell.engineer_name_bn : deepTubwell.engineer_name }} </p>
                            <p class="font-weight-bold text-center mb-0 mw-50">{{ $i18n.locale === 'bn' ? deepTubwell.org_name_bn : deepTubwell.org_name }}</p>
                          </td>
                          <td colspan="" rowspan="3">
                            <p class="font-weight-bold text-center mt-0">{{ $t('irriPumpInformation.drilling_contractor') }} </p>
                            <p class="font-weight-bold text-center mt-0">{{ $i18n.locale === 'bn' ? deepTubwell.drilling_contractor_name_bn : deepTubwell.drilling_contractor_name }} </p>
                          </td>
                          <td class="text-left"><b>{{ $t('irriPumpInformation.jl_no') }}</b></td>
                          <td>:</td>
                          <td class="text-left">{{ $n(deepTubwell.jl_no) }}</td>
                        </tr>
                        <tr>
                          <td class="text-left"><b>{{ $t('irriPumpInformation.plot_no') }}</b></td>
                          <td>:</td>
                          <td class="text-left">{{ $n(deepTubwell.plot_no) }}</td>
                        </tr>
                        <tr>
                          <td colspan="3"><h5 class="font-weight-bold"><u>{{ $t('irriPumpInformation.construction_details') }}</u></h5></td>
                        </tr>
                      </b-table-simple>
                    </b-row>
                      <b-row>
                      <b-table-simple small bordered hover responsive class="text-center">
                        <tr>
                          <td class="font-weight-bold">{{ $t('irriPumpInformation.operation') }}</td>
                          <td class="font-weight-bold">{{ $t('irriPumpInformation.started') }}</td>
                          <td class="font-weight-bold">{{ $t('irriPumpInformation.completed') }}</td>
                          <td rowspan="5"></td>
                          <td rowspan="29">
                            <b><u>{{ $t('irriPumpInformation.cross_section_of_the_well') }}</u></b> <br>
                            <b><u>{{ $t('irriPumpInformation.not_to_scale') }}</u></b> <br>
                          </td>
                        </tr>
                         <tr>
                          <td class="text-left">{{ $t('irriPumpInformation.drilling_diameter') }}</td>
                          <td class="text-right">{{ deepTubwell.drilling_start_time + ' ' + $t('irriPumpInformation.hrs') }}</td>
                          <td class="text-right">{{ deepTubwell.drilling_complete_time + ' ' + $t('irriPumpInformation.hrs') }}</td>
                        </tr>
                         <tr>
                          <td class="text-left">{{ $t('irriPumpInformation.casing_started') }}</td>
                          <td class="text-right">{{ deepTubwell.casing_start_time + ' ' + $t('irriPumpInformation.hrs') }}</td>
                          <td class="text-right">{{ deepTubwell.casing_complete_time + ' ' + $t('irriPumpInformation.hrs') }}</td>
                        </tr>
                         <tr>
                          <td class="text-left">{{ $t('irriPumpInformation.gravel_placing_started') }}</td>
                          <td class="text-right">{{ deepTubwell.gravel_placing_complete_time + ' ' + $t('irriPumpInformation.hrs') }}</td>
                          <td class="text-right">{{ (deepTubwell.gravel_placing_start_time) + ' ' + $t('irriPumpInformation.hrs') }}</td>
                        </tr>
                         <tr>
                          <td class="text-left font-weight-bold">{{ $t('irriPumpInformation.drilling_diameter') }}</td>
                          <td colspan="2">{{ deepTubwell.drilling_diameter + '"' }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left">{{ $t('irriPumpInformation.housing_pipe_dia') }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left">{{ $t('irriPumpInformation.uwc') }}: <span>........................{{ $n(deepTubwell.uwc_above_gl) }}........................</span> {{ $t('irriPumpInformation.UWC_above_GL') }}</td>
                        </tr>
                         <tr>
                           <td colspan="4" class="text-left">{{ $t('irriPumpInformation.uwc') }}: <span>........................{{ $n(deepTubwell.uwc_below_gl) }}........................</span> {{ $t('irriPumpInformation.UWC_below_GL') }}</td>
                        </tr>
                         <tr>
                          <td colspan="3" class="text-right">{{ $t('irriPumpInformation.total_length') }}</td>
                          <td>{{ $n(deepTubwell.uwc_total_gl) }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left font-weight-bold">{{ $t('irriPumpInformation.LWC') }}</td>
                        </tr>
                         <tr>
                          <td class="text-left">{{ $t('irriPumpInformation.depth_below_GL') }}</td>
                          <td class="text-left">{{ $t('irriPumpInformation.from') }} <span>..........{{ $n(deepTubwell.lwc_from_gl) }}..........</span></td>
                          <td class="text-left">{{ $t('irriPumpInformation.to') }} <span>..........{{ $n(deepTubwell.lwc_to_gl) }}..........</span> {{ $t('irriPumpInformation.ft') }}</td>
                          <td class="text-left"></td>
                        </tr>
                         <tr>
                          <td colspan="3" class="text-right font-weight-bold">{{ $t('irriPumpInformation.total_length') }}</td>
                          <td>{{ $n(deepTubwell.lwc_total_gl) }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left font-weight-bold">{{ $t('irriPumpInformation.screen_dia') }}</td>
                        </tr>
                          <tr>
                          <td class="text-left">{{ $t('irriPumpInformation.depth_below_GL') }}</td>
                          <td class="text-left">{{ $t('irriPumpInformation.from') }} <span>..........{{ $n(deepTubwell.screen_from_gl) }}..........</span></td>
                          <td class="text-left">{{ $t('irriPumpInformation.to') }} <span>..........{{ $n(deepTubwell.screen_to_gl) }}..........</span> {{ $t('irriPumpInformation.ft') }}</td>
                          <td class="text-left"></td>
                        </tr>
                         <tr>
                          <td colspan="3" class="text-right font-weight-bold">{{ $t('irriPumpInformation.total_length') }}</td>
                          <td>{{ $n(deepTubwell.screen_total_gl) }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left font-weight-bold">{{ $t('irriPumpInformation.bail_plug') }}</td>
                        </tr>
                         <tr>
                          <td class="text-left">{{ $t('irriPumpInformation.depth_below_GL') }}</td>
                          <td class="text-left">{{ $t('irriPumpInformation.from') }} <span>..........{{ $n(deepTubwell.bail_plug_from_gl) }}..........</span></td>
                          <td class="text-left">{{ $t('irriPumpInformation.to') }} <span>..........{{ $n(deepTubwell.bail_plug_to_gl) }}..........</span> {{ $t('irriPumpInformation.ft') }}</td>
                          <td class="text-left"></td>
                        </tr>
                         <tr>
                          <td  colspan="4" class="text-left font-weight-bold">{{ $t('irriPumpInformation.centralizes') }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left font-weight-bold" style="border-bottom: none;">{{ $t('irriPumpInformation.depth_below_GL') }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" style="border-top: none;border-bottom: none;" class="text-left"><p>{{ deepTubwell.centralize_description }}</p></td>
                        </tr>
                         <tr>
                          <td colspan="3" class="text-right font-weight-bold"> {{ $t('irriPumpInformation.tot_number') }}</td>
                          <td>{{ $n(deepTubwell.centralize_total) }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left font-weight-bold">{{ $t('irriPumpInformation.gravel') }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left">{{ $t('irriPumpInformation.gravel_quantity') }}</td>
                        </tr>
                         <tr>
                          <td class="text-left">{{ $t('irriPumpInformation.depth_below_GL') }}</td>
                          <td class="text-left">{{ $t('irriPumpInformation.from') }} <span>..........{{ $n(deepTubwell.gravel_depth_form) }}..........</span></td>
                          <td class="text-left">{{ $t('irriPumpInformation.to') }}<span>..........{{ $n(deepTubwell.gravel_depth_to) }}..........</span> {{ $t('irriPumpInformation.ft') }}</td>
                          <td class="text-left"></td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left font-weight-bold">{{ $t('irriPumpInformation.fixture_length') }}</td>
                        </tr>
                         <tr>
                          <td colspan="3" class="text-left"><span>....................{{ $n(deepTubwell.fixture_length) }}....................</span>{{ $t('irriPumpInformation.uwctoBail') }}</td>
                          <td></td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left font-weight-bold">{{ $t('irriPumpInformation.verticality_test') }}</td>
                        </tr>
                         <tr>
                          <td colspan="3" class="text-left">{{ $t('irriPumpInformation.max_deviation') }} <span>....................{{ $n(deepTubwell.max_deviation) }}....................</span> {{ $t('irriPumpInformation.inch_at') }} <span>....................{{ $n(deepTubwell.max_depth) }}....................</span> {{ $t('irriPumpInformation.depth') }}</td>
                          <td class="text-left"></td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left">{{ $t('irriPumpInformation.banglish') }}</td>
                        </tr>
                         <tr>
                          <td colspan="4" class="text-left">{{ $t('irriPumpInformation.date') }}</td>
                          <td><b>{{ $t('irriPumpInformation.ordered_depth') }}</b> <br />
                          ({{ $t('irriPumpInformation.glToBailPlug') }})
                          </td>
                        </tr>
                      </b-table-simple>
                    </b-row>
                    <b-row class="mb-1 mt-5 text-black">
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.sub_assistant_engineer') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda_dri') }} ................................
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.assistant_engineer') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ...............................
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.for_the_contractor') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.date') }} ...........................
                      </b-col>
                    </b-row>
                  </b-col>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { commonServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from './export_pdf_details'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.deepTubwell = this.item
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl,
      deepTubwell: '',
      slOffset: 1
    }
  },
  computed: {
    location_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name_bn, key1: this.$t('irriPumpInformation.pump_id'), val1: this.$n(this.deepTubwell.pump_id) },
          { key: this.$t('complain.division'), val: this.deepTubwell.division_name_bn, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name_bn },
          { key: this.$t('irriPumpInformation.mouza_no'), val: this.deepTubwell.mouza_no, key1: this.$t('irriPumpInformation.jl_no'), val1: this.$n(this.deepTubwell.jl_no, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.plot_no'), val: this.$n(this.deepTubwell.plot_no, { useGrouping: false }) }
        ]
      } else {
          return [
            { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name, key1: this.$t('irriPumpInformation.pump_id'), val1: this.deepTubwell.pump_id },
            { key: this.$t('complain.division'), val: this.deepTubwell.division_name, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name },
            { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name },
            { key: this.$t('irriPumpInformation.mouza_no'), val: this.deepTubwell.mouza_no, key1: this.$t('irriPumpInformation.jl_no'), val1: this.deepTubwell.jl_no },
            { key: this.$t('irriPumpInformation.plot_no'), val: this.deepTubwell.plot_no }
          ]
      }
    },
    installation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
          ]
      }
    },
    rehabilitation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
          ]
      }
    },
    housing_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
          ]
      }
    },
    filter_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
          ]
      }
    },
    installed_column_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
          ]
      }
    },
    vertical_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
          ]
      }
    },
    turbine_submersible_motor_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
          ]
      }
    },
    kharif_1_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
          ]
      }
    },
    kharif_2_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
          ]
      }
    },
    robi_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
          ]
      }
    },
    beneficial_farmer_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
          ]
      }
    },
    tot_uses_unit_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
          ]
      }
    },
    irri_adai_descrip_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.tot_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.total_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
          ]
      }
    },
    mnge_ment_cost_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
          ]
      }
    },
    mnge_ment_cost_bottom_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
          ]
      }
    }
  },
  methods: {
    time12Formate (time) {
      const timeIndex1st = time.split(':')[0]
      const timeIndex3rd = time.split(':')[1]
      if (timeIndex1st >= 12) {
        return (timeIndex1st - 12) + ':' + timeIndex3rd + ' PM'
      } else {
        return timeIndex1st + ':' + timeIndex3rd + ' AM'
      }
    },
    pdfExport () {
      const reportTitle = this.$t('irriPumpInformation.construction_details_report')
      ExportPdf.exportPdfDetails(reportTitle, this.item, this)
    }
  }
}
</script>
<style lang="scss">
  .details {
    font-size: 8px;
    color: #000;
    h5 {
      color: green !important;
      font-size: 12px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
    .table-bordered th {
      text-align: center;
      font-size: 9px !important;
      font-weight: 900 !important;
    }
    .table-bordered tr td {
      font-size: 9px !important;
      text-align: center;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
